<template>
	<div class="form-col">
		<div class="text-h6">Pet Description <i class="text-caption optional">(optional)</i></div>
		<div class="text-caption desc">
			Add a custom description of your pet! Please do not include any links or inappropriate languages.
		</div>
		<v-textarea
			class="pt-3"
			:rules="rules"
			v-model="description"
			label="Pet Description"
			counter="1500"
			dense
			outlined
		></v-textarea>
	</div>
</template>

<script>
const field = 'description';

export default {
	name: 'CustomPetFormDesc',
	data: () => ({
		rules: [
			(v) => !v || v.length <= 1500 || 'Pet name must be under 1500 characters',
		],
	}),
	computed: {
		description: {
			get() {
				return this.$store.getters.customPetForm[field];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>

.form-col {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
}

.optional {
	color: rgba(255, 255, 255, 0.7);
}

</style>
