<template>
	<div class="form-row">
		<div class="text-h6">
			Upload your custom pet image
		</div>
		<div class="text-caption desc">
			Pet images must follow a few rules.
			<ul>
				<li>The pet must be appropriate for all ages.</li>
				<li>The pet cannot be a real life image and must contain at least a face.</li>
				<li>You can change the stats of the pet if you request it within 1 week of pet creation.</li>
				<li>Once the pet is created, there is no refund.</li>
			</ul>
		</div>
		<FileUpload
			class="upload"
			ref="fileUpload"
	 		@fileSrc="updateFileSrc"
	 		:maxSize="maxSize"
	 		:imageOverlay="resultPet"
	 		:sizeOverride="sizeOverride"
		/>
	</div>
</template>

<script>
import FileUpload from '@/components/FileUpload';
import {encode, decodeFrames } from 'modern-gif';

const field = "image";

export default {
	name: 'CustomPetFormImage',
	components: { FileUpload },
	data: () => ({
		resultPet: null,
		errorMsg: null,
		maxSize: 262144,
		sizeOverride: 0
	}),
	methods: {
		removeFile() {
			this.$refs.fileUpload.removeFile();
		},
		async updateFileSrc(fileInfo) {
			const { file, src } = fileInfo || {}
			if (!src) {
				this.resultPet = null;
				return;
			}

			try {
				await this.showLoading((async () => {
					let blob;
					if (file.type === "image/gif") {
						blob = await this.alterGif(file, src);
					} else {
						blob = await this.alterPic(src, file.type);
					}

					if (blob.size > this.maxSize) {
						this.errorMsg = "The pet image is too large!";
						throw "max-size"
					}
					this.sizeOverride = blob.size;
					this.$store.dispatch('updateCustomPetForm', { field, value: blob });
					this.resultPet = URL.createObjectURL(blob);
				})());
				await this.showSuccess();
			} catch (err) {
				this.sizeOverride = 0;
				this.resultPet = null;
				this.$refs.fileUpload.removeFile();
				await this.showError();
				this.errorMsg = null;
			}
		},
		alterPic(src, fileType) {
			let mime;
			console.log(fileType);
			if (
				fileType.includes('png')
				|| fileType.includes('svg')
				|| fileType.includes('webp')
			) {
				mime = 'image/png';
			} else {
				mime = 'image/jpeg';
			}
			return new Promise((res, rej) => {
				const image = new Image();
				image.onload = (event) => {
					try {
						const { canvas } = this.getCanvas(image, image.width, image.height);
						canvas.toBlob((blob) => {
							res(blob);
						}, mime);
					} catch (err) {
						rej(err);
					}
				};
				image.src = src;
			});
		},
		alterGif(file, src) {
			return new Promise((res, rej) => {
				let reader = new FileReader();

				reader.onload = async (e) => {
					try {
						let arrayBuffer = new Uint8Array(reader.result);
						const frames = await decodeFrames(arrayBuffer);
						const size = Math.max(frames[0].width, frames[0].height);
						frames.forEach((frame) => {
							const image = new ImageData(frame.data, frame.width, frame.height);
							const { canvas } = this.getCanvas(image, size, size, true);
							frame.data = canvas;
							frame.width = size;
							frame.height = size;
						});
						const output = await encode({
							width: size,
							height: size,
							frames,
						})

						res(new Blob([output], { type: 'image/gif' }));
					} catch(err) {
						rej(err);
					}
				}

				reader.readAsArrayBuffer(file);
			});
		},
		getCanvas(image, width, height, imageData) {
			const size = Math.max(height, width);
			const canvas = document.createElement('canvas');
			canvas.width = size;
			canvas.height = size;
			const ctx = canvas.getContext('2d');

			if (imageData) {
				ctx.putImageData(
					image,
					(size / 2) - (image.width / 2),
					(size / 2) - (image.height / 2)
				); 
			} else {
				ctx.drawImage(
					image,
					(size / 2) - (image.width / 2),
					(size / 2) - (image.height / 2)
				); 
			}
			const lineWidth = Math.max(1, Math.round(size * 0.02));
			ctx.lineWidth = lineWidth;

			const grad = ctx.createLinearGradient(0, 0, size, size);
			grad.addColorStop(0, this.$vuetify.theme.themes.dark.secondary);
			grad.addColorStop(0.35, this.$vuetify.theme.themes.dark.secondary);
			grad.addColorStop(0.65, this.$vuetify.theme.themes.dark.primary);
			grad.addColorStop(1, this.$vuetify.theme.themes.dark.primary);
			ctx.strokeStyle = grad
			ctx.strokeRect(lineWidth / 2, lineWidth / 2, size - lineWidth, size - lineWidth);

			return { canvas, ctx, size };
		},
		showError() {
			const opt = {
				text: this.errorMsg || "Sorry! I failed to edit your picture! Please use a different pet image!",
				imgUrl: 'owo-cry.png',
			};
			return this.$modal(opt).showError();
		},
		showLoading(promise) {
			const opt = {
				text: 'I\'m currently editing the pet image! Please wait a moment...',
				imgUrl: 'owo-peek.png',
				persistent: true,
				loading: true,
				wait: promise,
			};
			return this.$modal(opt).showInfo();
		},
		showSuccess(text) {
			const opt = {
				text: 'Successfully edited your pet image! Looks very good <3',
				imgUrl: 'owo-peek.png'
			};
			return this.$modal(opt).showInfo();
		},
	}
};
</script>

<style scoped>

.form-row {
	width: 100%;
	padding-top: 15px;
	display: flex;
	flex-direction: column;
}

.upload {
	width: 100%;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
}

</style>
