<template>
	<div class="form-col">
		<div class="text-h6">Pet Stats</div>
		<div class="text-caption desc">
			Set your stats for your pet. It must add up to a total of 20 points and each stat must have at least one point.	
		</div>
		<div class="form-row">
			<v-img
				src="https://cdn.discordapp.com/emojis/531620120410456064.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="hp"
				label="HP"
				type="number"
				required
				dense
				outlined
			></v-text-field>
			<v-img
				src="https://cdn.discordapp.com/emojis/531616155450998794.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="att"
				label="ATT"
				type="number"
				required
				dense
				outlined
			></v-text-field>
			<v-img
				src="https://cdn.discordapp.com/emojis/531616156222488606.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="pr"
				label="PR"
				type="number"
				required
				dense
				outlined
			></v-text-field>
		</div>
		<div class="form-row">
			<v-img
				src="https://cdn.discordapp.com/emojis/531620120976687114.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="wp"
				label="WP"
				type="number"
				required
				dense
				outlined
			></v-text-field>
			<v-img
				src="https://cdn.discordapp.com/emojis/531616156231139338.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="mag"
				label="MAG"
				type="number"
				required
				dense
				outlined
			></v-text-field>
			<v-img
				src="https://cdn.discordapp.com/emojis/531616156226945024.png"
				class="stat-icon"
				max-height="30"
				max-width="30"
			/>
			<v-text-field
				class="stat"
				:rules="rules"
				v-model="mr"
				label="MR"
				type="number"
				required
				dense
				outlined
			></v-text-field>
		</div>
	</div>
</template>

<script>
const field = 'stats';

export default {
	name: 'CustomPetFormName',
	data: () => ({
		rules: [
			(v) => !!v || 'required',
			(v) => parseInt(v) > 0 || 'Must be greater than 0',
		],
	}),
	computed: {
		hp: {
			get() {
				return this.$store.getters.customPetForm['hp'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'hp', value });
			},
		},
		att: {
			get() {
				return this.$store.getters.customPetForm['att'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'att', value });
			},
		},
		pr: {
			get() {
				return this.$store.getters.customPetForm['pr'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'pr', value });
			},
		},
		wp: {
			get() {
				return this.$store.getters.customPetForm['wp'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'wp', value });
			},
		},
		mag: {
			get() {
				return this.$store.getters.customPetForm['mag'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'mag', value });
			},
		},
		mr: {
			get() {
				return this.$store.getters.customPetForm['mr'];
			},
			set(value) {
				this.$store.dispatch('updateCustomPetForm', { field: 'mr', value });
			},
		},
	},
};
</script>

<style scoped>

.form-row {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.form-row {
	display: flex;
}

.stat-icon {
	margin-top: 4px;
	margin-right: -5px;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
	padding-bottom: 12px;
}
</style>
