<template>
	<v-form
		ref="form"
		class="form"
		lazy-validation
	>
		<v-card-title class="pt-8">
			<v-avatar size="32" class="avatar">
				<v-img :src="avatarUrl" alt="avatar" />
			</v-avatar>
			Create your custom pet!
		</v-card-title>
		<div class="form-fields">
			<CustomPetFormName />
			<CustomPetFormAlt />
			<CustomPetFormStats />
			<CustomPetFormDesc />
			<div class="form-row">
				<CustomPetFormCreators />
				<CustomPetFormCreators gift />
			</div>
			<CustomPetFormImage ref="formImage" />
		</div>
		<v-card-actions class="actions">
			<v-spacer></v-spacer>
			<div v-if="errorMsg" class="error-text">{{ errorMsg }}</div>
			<v-btn color="primary" @click="submit" :loading="loading">
				Submit
			</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import endpoints from '@/router/routes.js';
import CustomPetFormName from '@/views/custom-pet/components/form/CustomPetFormName';
import CustomPetFormAlt from '@/views/custom-pet/components/form/CustomPetFormAlt';
import CustomPetFormStats from '@/views/custom-pet/components/form/CustomPetFormStats';
import CustomPetFormDesc from '@/views/custom-pet/components/form/CustomPetFormDesc';
import CustomPetFormCreators from '@/views/custom-pet/components/form/CustomPetFormCreators';
import CustomPetFormImage from '@/views/custom-pet/components/form/CustomPetFormImage';

export default {
	name: 'CustomPetForm',
	components: { CustomPetFormName, CustomPetFormStats, CustomPetFormDesc, CustomPetFormCreators, CustomPetFormAlt, CustomPetFormImage },
	data: () => ({
		form: null,
		errorMsg: null,
		loading: false, 
	}),
	computed: {
		avatarUrl() {
			if (this.user.avatar) {
				return `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`;
			} else {
				return `https://cdn.discordapp.com/embed/avatars/${
					parseInt(this.user.discriminator) % 5
				}.png`;
			}
		},
		user() {
			return this.$store.getters.user;
		},
	},
	methods: {
		async submit() {
			this.errorMsg = null;
			this.loading = true;
			const form = this.$store.getters.customPetForm;
			const totalStats = parseInt(form.hp) + parseInt(form.att) + parseInt(form.pr) + parseInt(form.wp) + parseInt(form.mag) + parseInt(form.mr);
			if (totalStats != 20) {
				this.errorMsg = `Stats must equal a total of 20 points`;
				this.loading = false;
				return;
			}
			if (!form.image) {
				this.errorMsg = `Please add a pet image`;
				this.loading = false;
				return;
			}

			try {
				await this.$store.dispatch('submitCustomPetForm', form);
			} catch (err) {
				const opt = {
					text: 'Failed to submit the pet... try again later!',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
			} finally {
				this.loading = false;
			}
			const opt = {
				text: 'Successfully submitted your pet. It will be added to your account once it is approved!',
				imgUrl: 'owo-peek.png',
				confetti: true,
			};
			await this.$modal(opt).showInfo();
			this.$refs.formImage.removeFile();
			this.$refs.form.reset();
			await this.$store.dispatch('resetCustomPetForm');
			this.$router.replace(endpoints.CUSTOM_PET);
		}
	},
	async mounted() {
		if (this.$store.getters.customPetTickets === 0) {
			this.$router.replace(endpoints.CUSTOM_PET);
		}
	},
};
</script>

<style scoped>
.form {
	width: 100%;
	height: 100%;
}

.actions {
	padding: 16px;
	width: 100%;
}

.avatar {
	margin-right: 10px;
}

.form-fields {
	padding: 0px 16px;
}

.error-text {
	color: var(--v-error-base);
	padding-right: 10px;
}

.form-row {
	width: 100%;
	display: flex;
	gap: 0px 10px;;
}
</style>
