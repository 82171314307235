<template>
	<div class="form-col">
		<div class="text-h6">Pet Nicknames <i class="text-caption optional">(optional)</i></div>
		<div class="text-caption desc">
			Create up to 5 unique nicknames for your pet. Please separate each name with a comma. Names must be appropriate and only include alphanumeric characters and underscores.
		</div>
		<v-text-field
			:rules="rules"
			v-model="alt"
			label="Pet Aliases (comma separated)"
			dense
			outlined
		></v-text-field>
	</div>
</template>

<script>
const field = 'alt';

export default {
	name: 'CustomPetFormName',
	data: () => ({
		rules: [
			(v) => {
				if (!v) return true;
				const names = v?.split(',') || [];
				if (names.length > 5) {
					return 'You can only have 5 aliases.';
				}
				for (let i in names) {
					const name = names[i];
					if (name.length > 32) {
						return 'Pet name must be under 32 characters';
					} else if (name.length < 3) {
						return 'Pet name must be at least 2 characters';
					}
				}
				return true;
			},
			(v) => !v || !!v.match(/^[a-zA-z0-9_,]+$/) || 'Pet name can only contain alphanumeric characters and underscores',
		],
	}),
	computed: {
		alt: {
			get() {
				const alt = this.$store.getters.customPetForm[field] || [];
				return alt.join(',');
			},
			set(value) {
				value = value?.split(',') || [];
				this.$store.dispatch('updateCustomPetForm', { field, value });
			},
		},
	},
};
</script>

<style scoped>

.form-col {
	display: flex;
	flex-direction: column;
}

.desc {
	color: rgba(255, 255, 255, 0.7);
	padding-bottom: 12px;
}

.optional {
	color: rgba(255, 255, 255, 0.7);
}

</style>
